<template>
  <el-container class="main-container">
    <el-row>
      <el-button @click="add">新增</el-button>
    </el-row>
    <el-card class="mt20">
      <el-row :gutter="10">
        <el-col :span="4" :offset="0">
          <el-input
            v-model="queryInfo.query"
            placeholder="请输入内容"
            clearable
            @clear="query"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="query"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <ele-select v-model="queryInfo.state"
            placeholder="状态筛选"
            :options="dataStateList"
            @change="query"></ele-select>
        </el-col>
      </el-row>
      <ele-table
        :columns-desc="columns"
        :is-show-index="true"
        :request-fn="getAppTags"
        :right-buttons="rightButtons"
        :is-show-top-delete="false"
        :is-show-right-delete="false"
        ref="table"
      ></ele-table>
    </el-card>
    <ele-form-dialog
      v-model="formData"
      :form-desc="formDesc"
      :title="isEdit ? '编辑标签' : '添加标签'"
      :request-fn="handleSubmit"
      :visible.sync="dialogVisible"
      @closed="handleClosed"
    ></ele-form-dialog>
  </el-container>
</template>

<script>
import { tableDataStateList} from "@/assets/js/constant.js";
export default {
  data() {
    return {
      queryInfo: {
        query: "",
        state: ""
      },
      dataStateList: tableDataStateList,
      columns: {
        name: {
          text: "标签名称",
        },
        desc: {
          text: '描述'
        },
        dataState: {
          text: '数据状态',
          type: 'status',
          options: tableDataStateList
        }
      },
      rightButtons: [
        {
          text: "编辑",
          attrs: {
            type: "primary",
          },
          click: (id, index, data) => {
            this.edit(data);
          },
        },
        {
          text: "删除",
          attrs: {
            type: "danger",
          },
          click: (id, index, data) => {
            this.remove(data);
          },
        },
        {
          text: '恢复',
          attrs: {
            type: 'success'
          },
          click: (id, index, data) => {
            this.restore(data)
          }
        }
      ],
      isEdit: false,
      dialogVisible: false,
      formData: {},
      formDesc: {
        name: {
          label: "分类名称",
          type: "input",
          required: true,
        },
        level: {
          label: '等级',
          type: 'input',
          attrs: {
            type: 'number',
            step: '1',
            min: 0
          }
        },
        desc: {
          label: '描述',
          type: 'textarea'
        }
      },
    };
  },
  created() {},
  methods: {
    query() {
      this.$refs.table.getData();
    },
    async getAppTags(params) {
      const { data: res } = await this.$api.getAppTags(
        Object.assign(params, this.queryInfo)
      );
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      return res.data;
    },
    add() {
      this.isEdit = false;
      this.dialogVisible = true;
    },
    edit(data) {
      this.isEdit = true;
      this.formData = data;
      this.dialogVisible = true;
    },
    async remove(data) {
      const confirm = await this.$confirm(
        "此操作将删除该标签, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }
      );
      if (confirm === "confirm") {
        const { data: res } = await this.$api.removeAppTag(data.id, false);
        if (res.code > 0) {
          return this.$notify.error({
            title: '错误',
            message: res.message
          })
        }
        this.$notify.success({
          title: '成功',
          message: res.message
        })
        this.query();
      }
    },
    async restore(data) {
      const confirm = await this.$confirm(
        "此操作将恢复该标签, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }
      );
      if (confirm === "confirm") {
        const { data: res } = await this.$api.removeAppTag(data.id, true);
        if (res.code > 0) {
          return this.$notify.error({
            title: '错误',
            message: res.message
          })
        }
        this.$notify.success({
          title: '成功',
          message: res.message
        })
        this.query();
      }
    },
    async handleSubmit(data) {
      const { data: res } = await this.$api.editAppTag(data);
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.dialogVisible = false;
      this.query();
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    },
    handleClosed() {
      this.formData = {};
    },
  },
};
</script>